* {
    transition: all 40ms;
    box-sizing: border-box;
}
html {
    margin: env(safe-area-inset);
    padding: env(safe-area-inset);
    scroll-behavior: smooth;
    font-family: Arial, sans-serif;
    color: black;
    overflow-y: scroll;
    background-color: #ee1cc0;
    white-space: normal;
}
body {
    background-color: #ee1cc0;
    overflow: hidden;
    overscroll-behavior-y: none;
    background: linear-gradient(#ffcd01, #ee1cc0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}
img {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}